import styles from './MoloSection.module.scss'
import Section from 'components/Section'
import { get as _get } from 'lodash-es'
import { Box } from '@achieve/ascend'
import { MediaImageStandard, Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink'
import { ArrowRight } from 'react-feather'
import AppStoreButtons from 'components/AppStoreButtons/AppStoreButtons'
import SlideOnVisible from 'components/SlideOnVisible'
import classNames from 'classnames'
import {
  SLIDE_ON_VISIBLE_EASING_DEFAULT,
  SLIDE_ON_VISIBLE_DURATION_DEFAULT,
  SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT,
} from 'constants/animation'

const imageVariationDefault = (imageBackground, imageTop) => {
  return (
    <Box className={styles['molo-card-image']}>
      <SlideOnVisible
        duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
        timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
        easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
      >
        <Box className={styles['molo-card-image-bg']}>
          <span>
            <MediaImageStandard content={imageBackground} layout="fixed" height="460" width="360" />
          </span>
        </Box>
      </SlideOnVisible>
      <SlideOnVisible
        duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
        timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
        easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
      >
        <Box className={styles['molo-card-image-top']}>
          <span>
            <MediaImageStandard
              content={imageTop}
              layout="fixed"
              width="270"
              height="300"
              style={{
                height: '100%',
              }}
            />
          </span>
        </Box>
      </SlideOnVisible>
    </Box>
  )
}

const imageVariationReverse = (imageBackground, imageTop) => {
  return (
    <Box className={classNames(styles['molo-card-image'], styles['molo-card-image-reverse'])}>
      <SlideOnVisible
        duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
        timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
        easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
      >
        <Box className={styles['molo-card-image-top']}>
          <span>
            <MediaImageStandard
              content={imageBackground}
              layout="fixed"
              height="150"
              width="500"
              style={{
                width: '100%',
              }}
            />
          </span>
        </Box>
      </SlideOnVisible>
      <SlideOnVisible
        duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
        timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
        easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
      >
        <Box className={styles['molo-card-image-bg']}>
          <span>
            <MediaImageStandard
              content={imageTop}
              layout="fixed"
              width="270"
              height="300"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </span>
        </Box>
      </SlideOnVisible>
    </Box>
  )
}

function makeSection(params, index) {
  const title = _get(params, 'fields.title')
  const header = _get(params, 'fields.header')
  const content = _get(params, 'fields.content')
  const link = _get(params, 'fields.link')
  const linkAndroid = _get(params, 'fields.linkAndroid')
  const linkApple = _get(params, 'fields.linkApple')
  const imageApple = _get(params, 'fields.imageApple')
  const imageAndroid = _get(params, 'fields.imageAndroid')
  const imageBackground = _get(params, 'fields.imageBackground')
  const imageTop = _get(params, 'fields.imageTop')
  const isToLeft = !(index % 2 == 0)
  const configObject = _get(params, 'fields.configObject')

  // configObject.backgroudType shloud handle variation by default is usisng molo variation backgroudType set to 2 is the GOOD App variation

  return (
    <Box
      className={classNames(
        styles['molo-card'],
        ...[configObject?.backgroudType == 2 ? styles['molo-card-reverse'] : null]
      )}
      key={`section-molo-${index}`}
      data-align-left={isToLeft}
    >
      <Box className={styles['molo-card-box']} data-testid="molo-card-container">
        <Box className={styles['molo-card-text']}>
          <SlideOnVisible
            duration={1150}
            timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
            easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
          >
            <div>
              <Typography
                content={title}
                className={classNames(
                  configObject?.backgroudType == 2
                    ? styles['molo-card-text-title-black']
                    : styles['molo-card-text-title'],
                  'bodySm',
                  configObject?.backgroudType == 2 ? 'font-medium' : 'font-bold'
                )}
              />
              <Typography
                content={header}
                className={classNames(
                  styles['molo-card-text-header'],
                  'displayMd lg:displayLg font-bold'
                )}
              />
              <Typography
                content={content}
                className={styles['molo-card-text-content']}
                variant="bodySm"
                fontWeight="regular"
                data-testid="molo-card-text-content"
              />
              {(linkAndroid || linkApple) && (
                <AppStoreButtons
                  appleImage={imageApple}
                  googleImage={imageAndroid}
                  appleButton={linkApple}
                  googleButton={linkAndroid}
                  navLinkSection="Value Props"
                />
              )}

              {link?.linkHref && (
                <AchieveLink href={link.linkHref}>
                  <div className={styles['molo-card-text-link']}>
                    <Typography content={link.linkText} fontWeight="bold" variant="bodyLg" />
                    <ArrowRight className={styles['link-arrow-right']} size={22} />
                  </div>
                </AchieveLink>
              )}
            </div>
          </SlideOnVisible>
        </Box>
      </Box>
      {configObject?.backgroudType == 2
        ? imageVariationReverse(imageBackground, imageTop)
        : imageVariationDefault(imageBackground, imageTop)}
    </Box>
  )
}

function MoloSection({ content }) {
  const sectionContents = _get(content, 'fields.sectionContents')

  return (
    <Section className={styles['molo-section']} contain={false}>
      {sectionContents.map((data, index) => makeSection(data, index))}
    </Section>
  )
}
export { MoloSection }
export default MoloSection
